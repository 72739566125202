import MapsLoader from "../map/mapsLoader";
import AjaxManager from "../ajaxManager";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {MapsPrinter} from "../map/mapPrinter";

export const GROUP_TYPE = 'GROUP';

export default class GroupListMap {
    constructor() {
        this.mapsPrinter = new MapsPrinter();
        this.userFavourites = null;
    }

    initialize(groupList) {
        this.mapsLoader = new MapsLoader();
        this.mapId = '#group-map';
        this.markers = [];
        this.markersPositions = [];
        this.selectedMarker = null;
        this.selectedPositionGroup = null;
        this.ajaxManager = new AjaxManager();
        this.data = [];
        this.selectedGroupId = myApp.urlManager.getUrlParam('openMap');
        this.selectedGroup = null;
        this.selectedMarkerElement = null;

        this.initMap(groupList).then();
        if (!this.selectedGroupId) {
            myApp.urlManager.setUrlParam('openMap', '');
        }
    };

    async initMap(groupList) {
        const {Map} = await google.maps.importLibrary("maps");
        const {AdvancedMarkerElement} = await google.maps.importLibrary("marker");
        this.map = new Map(document.querySelector(this.mapId), this.mapsLoader.getOptions());
        const favouritesResponse = await fetch(myApp.config.routing.getUserFavouriteGroups, {method: 'GET'})
            .then(response => response.json()).then(result => result);
        this.userFavourites = favouritesResponse.content;
        const clusterMarkers = this.appendEntities(groupList, AdvancedMarkerElement, GROUP_TYPE);

        this.initGoogleMap(clusterMarkers, this.map, AdvancedMarkerElement);
    }

    initGoogleMap(clusterMarkers, map, AdvancedMarkerElement) {
        if (clusterMarkers.length > 1) {
            new MarkerClusterer({
                map: map,
                markers: clusterMarkers,
                renderer: {
                    render: ({count, position}) =>
                        this.createClusterMarker(count, position, AdvancedMarkerElement)
                }
            });
        }

        const bounds = new google.maps.LatLngBounds();
        for (const marker of clusterMarkers) {
            bounds.extend(marker.position);
        }

        if (!bounds.isEmpty() && !this.selectedGroupId) {
            this.map.fitBounds(bounds);
        }
    }

    createClusterMarker(count, position, AdvancedMarkerElement) {
        const clusterContainer = document.createElement('div');
        clusterContainer.classList.add('cluster-marker');
        clusterContainer.innerHTML = String(count);

        return new AdvancedMarkerElement({
            position,
            content: clusterContainer
        })
    }

    appendEntities(entities, AdvancedMarkerElement, entityType) {
        const advancedMarkers = this.buildMarkers(AdvancedMarkerElement, entities, entityType);
        if ((entities.length === 1 && advancedMarkers.length === 1) || (this.selectedMarker && this.selectedGroup && this.selectedPositionGroup)) {
            this.map.setCenter(this.selectedPositionGroup.position);
            this.map.setZoom(this.map.getZoom() + 10);
            this.selectDefaultMarker(this.selectedGroup, this.selectedMarker, entityType);
        }
        return advancedMarkers;
    }

    buildMarkers(AdvancedMarkerElement, entities, entityType) {
        const advancedMarkers = [];
        this.map.setCenter({
            lat: parseFloat(entities[0].latitude),
            lng: parseFloat(entities[0].longitude)
        });

        entities.forEach(entity => {
            try {
                if (!entity.visible) {
                    return;
                }
                const marker = this.buildMarker(entity);
                this.markers.push(marker);
                let zIndex = 1;

                if (entity.highlighted) {
                    zIndex = 9999999;
                }

                let latitude = parseFloat(entity.latitude);
                let longitude = parseFloat(entity.longitude);
                while (true) {
                    if (!this.markersPositions.includes(latitude + ', ' + longitude)) {
                        break;
                    }
                    latitude += 0.0001;
                    longitude += 0.0001;
                }

                const markerElement = new AdvancedMarkerElement({
                    position: {
                        lat: latitude,
                        lng: longitude
                    },
                    map: this.map,
                    content: marker,
                    gmpClickable: true,
                    zIndex: zIndex
                });

                if (this.selectedGroupId == entity.id) {
                    this.selectedGroup = entity;
                }

                this.markersPositions.push(latitude + ', ' + longitude);
                advancedMarkers.push(markerElement);

                markerElement.addListener('click', (e) => {
                    markerElement.zIndex = 99999999;
                    if (this.selectedMarkerElement) {
                        this.selectedMarkerElement.zIndex = 1;
                    }
                    this.selectedMarkerElement = markerElement;
                    this.onClickMarker(entity, marker, entityType);
                });

                if (entity.id == this.selectedGroupId) {
                    this.selectedMarker = marker;
                    this.selectedPositionGroup = markerElement;
                }

            } catch (e) {
                console.log(entity.id);
            }
        });

        this.map.addListener("click", () => {
            this.unselectMarkers(this.markers);
        });

        return advancedMarkers;
    }

    unselectMarkers(markers) {
        markers.forEach((marker) => {
            if (marker.classList.contains('marker-selected')) {
                marker.classList.remove('marker-selected');
                marker.classList.add('marker-visited');
            }
            const icon = marker.querySelector('i.ci');
            if (icon) {
                icon.classList.add('ci-s-14');
                icon.classList.remove('ci-s-16');
            }
        });
        document.querySelector('[data-own-infowindow]').style.display = 'none';
    }

    buildMarker(entity) {
        const marker = document.createElement('div');
        marker.classList.add('marker');
        const markerContainer = document.createElement('div');

        let entityPrice = entity.price;
        marker.id = entity.id;

        if (entityPrice) {
            markerContainer.classList.add('d-flex', 'align-items-center', 'gap-1');
            markerContainer.setAttribute('data-entity', 'marker-text-container');

            const markerText = document.createElement('span');
            if (this.userFavourites) {
                if (this.userFavourites.includes(entity.id)) {
                    markerText.innerHTML = '<i class="ci ci-heart ci-s-14"></i>' + entityPrice + " €";
                } else {
                    markerText.innerHTML = entityPrice + " €";
                }
            } else {
                markerText.innerHTML = entityPrice + " €";
            }
            markerText.setAttribute('data-entity', 'marker-text');
            markerText.classList.add('d-flex', 'align-items-center', 'no-wrap', 'gap-1');
            markerContainer.appendChild(markerText);
        } else {
            marker.classList.add('marker-no-price');
        }

        marker.appendChild(markerContainer);
        return marker;
    }

    onClickMarker(entity, marker, entityType) {
        entity.isVisited = true;
        this.selectedGroupId = null;
        this.selectedGroup = null;
        this.unselectMarkers(this.markers);
        this.updateCustomInfoWindow(entity, marker, entityType);
        this.selectMarker(marker);
    }

    selectDefaultMarker(entity, marker, entityType) {
        this.updateCustomInfoWindow(entity, marker, entityType);
        this.selectMarker(marker);
    }


    updateCustomInfoWindow(entity, marker, entityType) {
        let url = myApp.config.routing.getGroupInfoWindow;
        let callback = (data) => {
            if (data != "") {
                this.data[entity.id] = data;
                this.mapsPrinter.activateMarker(marker, data);
                this.createInfoWindowFavouriteEvents(marker, entity.id);
            }
        };
        if (this.data[entity.id] === undefined) {
            this.groupId = entity.id;
            this.ajaxManager.ajaxCall('GET', url, this.getAjaxData(entity), callback.bind(this));
        } else {
            this.mapsPrinter.activateMarker(marker, this.data[entity.id]);
            this.createInfoWindowFavouriteEvents(marker, entity.id);
        }
    }

    selectMarker(marker) {
        if (marker.classList.contains('marker-visited')) {
            marker.classList.remove('marker-visited');
        }
        marker.classList.add('marker-selected');
        const icon = marker.querySelector('i.ci');
        if (icon) {
            icon.classList.remove('ci-s-14');
            icon.classList.add('ci-s-16');
        }
        document.querySelector('[data-own-infowindow]').style.display = 'block';
    }

    getAjaxData(group) {
        let favCounterContainer = document.querySelector('[data-entity="group"][data-id="' +
            group.id +'"] [data-entity="favourite-count"]');

        return {
            groupId: group.id,
            isFavourite: this.userFavourites ? this.userFavourites.includes(group.id) : false,
            favouriteCounter: favCounterContainer ? favCounterContainer.textContent.trim() : 0
        };
    }

    createInfoWindowFavouriteEvents(marker, groupId) {
        let infoWindow = document.querySelector('[data-own-infowindow]');
        let loginUserFavourite = infoWindow.querySelector('[data-open="login-user"]');
        if (loginUserFavourite) {
            loginUserFavourite.addEventListener('click', (event) => {
                event.stopPropagation();
                event.preventDefault();
                myApp.browserHistoryManager.removeState('#modal_group_map');
                myApp.user.loginManager.openLoginModal();
                myApp.customModalManager.openModal('#userModal');
            });
        }
        let self = this;
        let addFavourite = infoWindow.querySelector('[data-add-favourite]');
        if (addFavourite) {
            addFavourite.addEventListener('click', (event) => {
                event.stopPropagation();
                event.preventDefault();
                myApp.user.userWebFavouriteGroupManager.addFavouriteGroup(event.currentTarget);
                if (self.data[groupId] !== 'undefined') self.data.splice(groupId, 1);
                if (this.userFavourites && this.userFavourites.indexOf(groupId) === -1) {
                    this.updateFavouriteMarker(marker, true);
                    this.userFavourites.push(groupId);
                }
            });
        }
        let removeFavourite = infoWindow.querySelector('[data-action="remove-favourite"]');
        if (removeFavourite) {
            removeFavourite.addEventListener('click', (event) => {
                event.stopPropagation();
                event.preventDefault();
                myApp.user.userWebFavouriteGroupManager.removeFavouriteGroup(event.currentTarget);
                if (self.data[groupId] !== 'undefined') self.data.splice(groupId, 1);
                this.updateFavouriteMarker(marker, false);
                if (this.userFavourites) {
                    const index = this.userFavourites.indexOf(groupId);
                    if (index > -1) this.userFavourites.splice(index, 1);
                }
            });
        }
    }

    updateFavouriteMarker(marker, isFavourite) {
        let markerText = marker.querySelector('[data-entity="marker-text-container"]');
        if (isFavourite) {
            markerText.innerHTML = '<i class="ci ci-heart ci-s-14"></i>' + markerText.innerHTML;
        } else {
            markerText.querySelector('i').remove();
        }
    }
}