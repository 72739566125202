export default class GroupVideoManager {

    constructor() {
        this.eventDelegation();
    }

    eventDelegation() {
        let videos = document.querySelectorAll(".video-chollo-card");
        let videosPlayed = {};
        videos.forEach(function (video) {
            video.onloadeddata = () => {
                let observer = new IntersectionObserver(function (entries) {
                    const videoId = video.getAttribute('data-id');
                    if (entries[0].isIntersecting && video.paused) {
                        var playPromise = video.play()
                        if (playPromise !== undefined) {
                            playPromise.then(() => {
                                if (!video.paused && !videosPlayed.hasOwnProperty(videoId)) {
                                    videosPlayed[videoId] = playPromise;
                                }
                            }).catch(() => {
                                video.play();
                            });
                        }
                    } else if (videosPlayed.hasOwnProperty(videoId) && !video.paused) {
                        videosPlayed[videoId].then(() => {
                            video.pause();
                            delete videosPlayed[videoId];
                        });
                    }
                }, {threshold: 0.5});
                observer.observe(video);
            }
        });
    }
}
