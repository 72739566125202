import $ from 'jquery';
import { Collapse } from 'bootstrap';
import MyAppConfig from "../myApp.config";
import BrowserStorageService from "../services/browserStorageService";
import UserManager from "../services/userManager";
import DeviceDetector from "../services/deviceDetector";
import UrlManager from "../services/urlManager";
import ScrollManager from "../services/scrollManager";
import DomAnimator from "../services/domAnimator";
import TextTransformer from "../services/dom/utilities/textTransformer";
import CollapseManager from "../services/dom/utilities/collapseManager";
import PopoverManager from "../services/dom/utilities/popoverManager";
import DateFormatter from "../services/dateFormatter";
import DomManager from "../services/dom/domManager";
import TranslatorService from "../services/translator/translatorService";
import AffixManager from "../services/affixManager";
import FormObserver from "../services/forms/formObserver";
import FormPrinter from "../services/forms/formPrinter";
import FormValidator from "../services/forms/formValidator";
import FormGroupValidator from "../services/forms/formGroupValidator";
import FormSubmitterManager from "../services/forms/formSubmitter";
import NewsletterSubscriptionValidator from "../services/forms/validator/newsletterSubscriptionValidator";
import DropdownManager from "../services/dom/utilities/dropdownManager";
import SubscribeManager from "../services/subscribeManager";
import ServicesController from "@web/vpt-assets/assets/js/controller/servicesController";
import CustomModalManager from "@web/vpt-assets/assets/js/utilities/customModalManager";
import BrowserHistoryManager from "@web/vpt-assets/assets/js/utilities/dom/browserHistoryManager";
import Lightbox from "../services/lightbox/lightbox";
import MenuManager from "../services/menu/menuManager";
import CollapseManagerNew from "@web/vpt-assets/assets/js/utilities/collapseManager";
import UserLoginRegisterModalHandler
    from "@web/vpt-assets/assets/js/modules/userLoginRegister/userLoginRegisterModalHandler";
import InfoActiveAccountHandler from "@web/vpt-assets/assets/js/modules/userLoginRegister/infoActiveAccountHandler";
import scrollAffixManager from "../services/scrollAffixManager";
import SearcherManager from "../services/search/searcherManager";
import SearchSuggest from "../services/search/searchSuggest";
import CopyTextManager from "../util/copyTextManager";
import HotelManager from "../services/hotel/hotelManager";
import TrackersManager from "../services/trackers/trackersManager";
import LazyLoad from "vanilla-lazyload";
import SliderManager from "../services/slider/sliderManager";
import CustomDropdownManager from "@web/vpt-assets/assets/js/utilities/dropdownManager";

global.$ = $;
global.jQuery = $;
global.jquery = $;

global.myApp = {
    initializer: {},
    form: {
        book: {},
        validator: {},
        dataTransformer: {}
    },
    controller: {
        subscribeController: {},
        step1Controller: {},
        step2Controller: {},
        step3Controller: {}
    },
    translator: {},
    mapBuilder: {},
    book: {},
    user: {},
    menu: {},
    filterer: {},
    components: {
        time: {}
    },
    tracking: {
        gtm: {},
        ga: {}
    },
    lightboxList: [],
    config: []
};

$(function () {
    myApp.myAppConfig = new MyAppConfig();
    myApp.config.rootPath = myApp.myAppConfig.rootPath;
    myApp.config.cdnPath = myApp.myAppConfig.cdnPath;
    myApp.config.analytics = myApp.myAppConfig.analytics;
    myApp.browserStorageService = new BrowserStorageService();
    myApp.userManager = new UserManager();
    myApp.deviceDetector = new DeviceDetector();
    myApp.urlManager = new UrlManager();
    myApp.scrollManager = new ScrollManager();
    myApp.domAnimator = new DomAnimator();
    myApp.textTransformer = new TextTransformer();
    myApp.collapseManager = new CollapseManager();
    myApp.popoverManager = new PopoverManager();
    myApp.dateFormatter = new DateFormatter();
    myApp.domManager = new DomManager();
    myApp.translatorService = new TranslatorService();
    myApp.affixManager = new AffixManager();
    myApp.form.observer = new FormObserver();
    myApp.form.formPrinter = new FormPrinter();
    myApp.formValidator = new FormValidator();
    myApp.formGroupValidator = new FormGroupValidator();
    myApp.formSubmitterManager = new FormSubmitterManager();
    myApp.form.validator.newsletterSubscriptionValidator = new NewsletterSubscriptionValidator();
    myApp.dropdownManager = new DropdownManager();
    myApp.subscribeManager = new SubscribeManager();
    const servicesController = new ServicesController();
    myApp.customModalManager = servicesController.getService(CustomModalManager.className, {'closeImmediately': myApp.config.parameters.isIOSApp});
    myApp.browserHistoryManager = servicesController.getService(BrowserHistoryManager.className);
    myApp.lightbox = new Lightbox();
    myApp.menu.manager = new MenuManager();
    myApp.collapseManagerNew = new CollapseManagerNew();
    myApp.user.loginManager = new UserLoginRegisterModalHandler();

    new InfoActiveAccountHandler({
        isUserLoggedIn: myApp.config.isUserLoggedIn,
        isActivateAccount: activateAccountInfo['userActivate']
    });
    new scrollAffixManager();
    new SearcherManager();
    new SearchSuggest();
    new CopyTextManager();
    new HotelManager();
    new TrackersManager();
    myApp.customDropdownManagerNew = new CustomDropdownManager();
    new LazyLoad({
        threshold: 500,
        elements_selector: ".vanilla-lazy",
        data_src: "lazy-src",
    });
});

myApp.initializer.arrowToTop = function ()
{
    var arrowScrollTop = $('.scroll-top-wrapper');
    if (arrowScrollTop.length > 0) {
        if ($(document).scrollTop() > 300) {
            arrowScrollTop.addClass('show-scroll');
            if (arrowScrollTop.offset().top > $('.footer-bottom').offset().top) {
                $('.scroll-top-wrapper').addClass('on-footer');
            } else {
                arrowScrollTop.removeClass('on-footer');
            }
        } else {
            arrowScrollTop.removeClass('show-scroll');
            arrowScrollTop.removeClass('on-footer');
        }
    }
};