export default class Lightbox
{
    constructor()
    {
        this.lightboxModal = document.querySelector('#invitation_modal');
        this.url = new URL(window.location.href);
        if (!this.lightboxModal || this.url.searchParams.has('itinerary')) return;
        this.hasInstaParam = this.url.searchParams.has('insta');
        this.hasUserExclusiveParam = this.url.searchParams.has('userExclusive');
        this.hasNewsletterLightboxCookie = document.cookie.includes('hide_newsletter_lightbox');

        if (!this.hasUserExclusiveParam) {
            if ((this.url.searchParams.has('new') || this.hasInstaParam) && !this.hasNewsletterLightboxCookie) {
                this.__loadLightBoxPreview();
            }
        }
        this.eventDelegation();
    }

    eventDelegation()
    {
        $('[data-action="load-lightbox"]').on('click', () => {
            this.hasInstaParam = true;
            this.__loadLightBoxPreview()
        });

        this.lightboxModal.addEventListener('custom-modal-hide', (event) => {
            let date = new Date;
            date.setDate(date.getDate() + (event.detail === 'modal-close' ? 30 : 7));
            document.cookie = "hide_newsletter_lightbox=true; expires=" + date;
            $('body').removeClass('newsletter-modal');
        });
    }

    __loadLightBoxPreview()
    {
        let postUrl = myApp.config.routing.lightbox;
        if (this.url.searchParams.get('lightboxId')) {
            postUrl += '/' + this.url.searchParams.get('lightboxId');
        }

        $.ajax({
            type: 'GET',
            url: postUrl,
            data: {
                locale: myApp.config.locale,
            }
        }).done((response) => {
            if (response.closeButtonColor) {
                if (myApp.deviceDetector.isMdScreen() || myApp.deviceDetector.isLgScreen()) {
                    $('#invitation_modal .modal-content').css('background-image', 'url("' + response.img + '")');
                }
                $('#invitation_modal [data-element="id-lightbox"]').val(response.lightBoxId);
                $('#invitation_modal [data-content="title"]').html(response.title);
                $('#invitation_modal [data-content="description"]').html(response.description);
                $('#invitation_modal [data-value="button"]').val(response.buttonText);
                $('#invitation_modal [data-action-src="image"]').removeAttr('data-src').attr('src', response.img);
            }
            setTimeout(this.openModal, (this.hasInstaParam ? 0 : 5000));
        });
    }
    openModal() {
        myApp.customModalManager.openModal('#invitation_modal');
        $('body').addClass('newsletter-modal');
    }
}
