import ScrollManager from "@web/vpt-assets/assets/js/utilities/scrollManager";
import DataLayerManager from "../trackers/dataLayer/dataLayerManager";

export default class GroupListPrinter {
    constructor() {
        this.scrollManager = new ScrollManager();
        this.bannerElementsById = [];
        this.dataLayerManager = new DataLayerManager();
        this.groupTypes = {
            onSale: {
                container: '[data-category="groups-on-sale"]',
            },
            comingSoon: {
                container: '[data-category="groups-coming-soon"]',
            },
            extra: {
                container: '[data-category="groups-extra"]',
            },
        }
        this._initializeView();
    }

    updateGroupsDistance(groups, userLocation) {
        let groupContainer;
        for (let group of groups) {
            group.calculateDistance(userLocation);
            groupContainer = group.container;
            if (groupContainer && group.distance) {
                groupContainer.querySelector('[data-km-distance]').innerHTML = group.distance + ' km';
                groupContainer.querySelector('[data-container="distance"]').classList.remove('hidden');
            }
        }
    }

    printGroups(groups, initialPosition) {
        let categoryGroupsContainers = {};
        let categoryKey;
        if (initialPosition) {
            document.querySelectorAll('[data-container="initial-group-list"] [data-category-id]').forEach((category) => {
                categoryGroupsContainers[category.dataset.categoryId] = category.querySelector('[data-container="groups"]');
            });
            categoryKey = 'type';
            document.querySelector('[data-container="initial-group-list"]').style.display = '';
            document.querySelector('[data-container="filtered-group-list"]').style.display = 'none';

        } else {
            Object.entries(this.groupTypes).forEach(([i, groupType]) => {
                categoryGroupsContainers[i] = document.querySelector(groupType.container + ' [data-container="groups"]');
            });
            categoryKey = 'sorterType';
            document.querySelector('[data-container="filtered-group-list"]').style.display = '';
            document.querySelector('[data-container="initial-group-list"]').style.display = 'none';
        }
        this._repaintGroups(groups, categoryGroupsContainers, categoryKey, initialPosition);
    }

    _initializeView() {
        let initializeEvents = document.querySelector('[data-seen]') && document.querySelector('[data-landing]');

        if (!initializeEvents) {
            return;
        }

        let banners = document.querySelectorAll('[data-banner]');
        let groups = document.querySelectorAll('[data-container-list="groups"] [data-entity="group"]');
        window.addEventListener("scroll", () => {
            this._initializeScrollEvents(banners, groups);
        });

        this._initializeClickEvents(banners, groups);
    }

    _initializeScrollEvents(banners, groups) {
        banners.forEach((bannerElement) => {
            if (this.scrollManager.isElementPartiallyVisible(bannerElement) && !parseInt(bannerElement.dataset.seen)) {
                bannerElement.dataset.seen = 1;
                this._bannerPushData(bannerElement, 'banner_impression');
            } else if (!this.scrollManager.isElementPartiallyVisible(bannerElement)) {
                bannerElement.dataset.seen = 0;
            }
        });
        groups.forEach((element) => {
            if (this.scrollManager.isElementPartiallyVisible(element) && !parseInt(element.dataset.seen)) {
                element.dataset.seen = 1;
                this._offerPushData(element, 'offer_impression');
            } else if (!this.scrollManager.isElementPartiallyVisible(element)) {
                element.dataset.seen = 0;
            }
        });
    }

    _initializeClickEvents(banners, groups) {
        banners.forEach((bannerElement) => {
            bannerElement.addEventListener('click', () => {
                this._bannerPushData(bannerElement, 'banner_click');
            });
            this.bannerElementsById[bannerElement.dataset.position] = bannerElement;
        });

        groups.forEach((element) => {
            element.addEventListener('click', () => {
                this._offerPushData(element, 'offer_click');
            });
        });
    }

    _repaintGroups(groups, categoryGroupsContainers, categoryKey, initialPosition) {
        this.scrollManager.scrollTo('#central_content', () => {
        }, 0, true, 100);

        let bannerIndex = initialPosition ? null : 0;

        const repaintBatch = (startIndex) => {
            const batchSize = 20; // Adjust the batch size as needed
            for (let i = startIndex; i < Math.min(startIndex + batchSize, groups.length); i++) {
                let group = groups[i];
                let categoryGroupsContainer = categoryGroupsContainers[group[categoryKey]];

                if (bannerIndex == null && group.type.includes('on_sale')) {
                    bannerIndex = 0;
                }
                if (bannerIndex != null && this.bannerElementsById.length > 0) {
                    if (this.bannerElementsById[bannerIndex]) {
                        categoryGroupsContainer.appendChild(this.bannerElementsById[bannerIndex]);
                    }
                    bannerIndex++;
                }

                const fragment = document.createDocumentFragment();
                fragment.appendChild(group.container);
                categoryGroupsContainer.appendChild(fragment);
                categoryGroupsContainer.closest('[data-category]').style.display = '';
            }

            if (startIndex + batchSize < groups.length) {
                requestAnimationFrame(() => repaintBatch(startIndex + batchSize));
            }
        };
        requestAnimationFrame(() => {
            this._resetGroupTypes();
            repaintBatch(0);
        });
    }

    _resetGroupTypes() {
        this._resetFilteredGroupList();
        this._resetInitialGroupList()
    }

    _resetFilteredGroupList() {
        let groupTypeContainer;

        Object.entries(this.groupTypes).forEach(([i, groupType]) => {
            groupTypeContainer = document.querySelector(groupType.container);
            if (!groupTypeContainer) {
                return;
            }
            groupTypeContainer.querySelector('[data-container="groups"]').innerHTML = '';

            groupTypeContainer.style.display = 'none';
        });
    }

    _resetInitialGroupList() {
        document.querySelectorAll('[data-container="initial-group-list"] [data-category-id]').forEach((category) => {
            category.querySelector('[data-container="groups"]').innerHTML = '';
            category.style.display = 'none';
        });
    }

    _bannerPushData(element, event) {
        let actualScreen = element.dataset.landing.replace(/^\/[^/]+/, '');
        this.dataLayerManager.pushData(JSON.stringify({
            'event': event,
            'screen': actualScreen.length > 0 ? actualScreen : 'home',
            'banner_id': element.dataset.banner
        }));
    }

    _offerPushData(element, event) {
        let actualScreen = element.dataset.landing.replace(/^\/[^/]+/, '');
        this.dataLayerManager.pushData(JSON.stringify({
            'event': event,
            'screen': actualScreen.length > 0 ? actualScreen : 'home',
            'offer': {
                'id': element.dataset.id,
                'name': element.dataset.name,
            }
        }));
    }
}