import CriteriaList from "./criteria/criteriaList";

export default class FilterPrinter {
    constructor(criteriaList, onFilterFunction) {
        this.collapseBtnTemplate = document.querySelector('[data-template="collapse-button"]');
        this._criteriaList = criteriaList;
        this.onFilterFunction = onFilterFunction;
        this.eventDelegation();
    }

    eventDelegation() {
        document.querySelectorAll('[data-open="filters"]').forEach(element => {
            element.addEventListener('click', (element) => {
                document.querySelector('[data-container="filters"]').classList.add('show');
                document.querySelector('body').classList.add('filters-opened');
            });
        });
        document.querySelectorAll('[data-close="filters"]').forEach((element) => {
            element.addEventListener('click', (element) => {
                document.querySelector('[data-container="filters"]').classList.remove('show');
                document.querySelector('body').classList.remove('filters-opened');
            });
        });
    }

    updateFilterView(filteredGroups, groups) {
        if (!document.querySelector('[data-container="filters"]')) return
        this._updateCountTexts(filteredGroups, groups);
        this._updateGetParams();
    }

    initializeFilters() {
        let getParams = new URLSearchParams(window.location.search);
        let filtersApplied = false;
        let criteria, valueArray, element;

        CriteriaList.criteriaTypes.forEach((criteriaType) => {
            if (!getParams.has(criteriaType)) {
                return;
            }
            valueArray = getParams.get(criteriaType);
            valueArray.split('_').forEach((value) => {
                criteria = this._criteriaList.getCriteria(criteriaType, value)
                if (!criteria) {
                    return;
                }
                element = this._getCriteriaElement(criteria);
                criteria.checked = true;
                element.checked = true;
                filtersApplied = true;
            });
        });

        return filtersApplied;
    }

    _updateGetParams() {
        let getParams = new URLSearchParams(window.location.search);
        let parametersModified = false;
        let criteriaCheckedList, criteriaIdList;

        CriteriaList.criteriaTypes.forEach((criteriaType) => {
            criteriaCheckedList = this._criteriaList.getCriteriaChecked(criteriaType);
            criteriaIdList = criteriaCheckedList.map((criteria) => {
                return criteria.valueA;
            });
            criteriaIdList = criteriaIdList.join('_');

            if (getParams.has(criteriaType) && !criteriaIdList) {
                getParams.delete(criteriaType);
                parametersModified = true;
            }
            if (criteriaIdList) {
                getParams.set(criteriaType, criteriaIdList);
                parametersModified = true;
            }
        });
        if (parametersModified) {
            window.history.replaceState({}, '', '?' + getParams.toString());
        }
    }

    setCriteriaVisibility(criteria) {
        let element = this._getCriteriaElement(criteria).closest('[data-hide="checkbox"]');
        element.querySelector('[data-field="groups-counter"]').innerHTML = criteria.numItems;

        if (criteria.numItems || criteria.checked) {
            element.classList.remove('disabled');
            criteria.visible = true;
        } else {
            element.classList.add('disabled');
            criteria.visible = false;
        }
    }

    _updateFilterCategories() {
        let numCriteriaType = 0;
        document.querySelectorAll('[data-container-filter-type]').forEach((element, index) => {
            numCriteriaType = this._criteriaList.getNumCriteriaType(element.dataset.containerFilterType);
            if (numCriteriaType > 0) {
                this._initializeCollapseCriteriaType(element, numCriteriaType, index);
            }
        });
    }

    _initializeCollapseCriteriaType(element, numCriteriaType, indexCategory) {
        let criteriaListContainers = element.querySelectorAll('[data-hide="checkbox"]');
        let criteriaContainer = element.querySelector('[data-container="criteria-list"]');

        criteriaContainer.innerHTML = '';
        let containerCollapsed = null;

        if (numCriteriaType > FilterPrinter.numCollapsedCriteria) {
            containerCollapsed = document.createElement('div');
            containerCollapsed.id = 'crieria-type-' + indexCategory;
            containerCollapsed.classList.add('d-flex', 'flex-column', 'gap-2');
        }

        let checkedCriteriaInsideCollapse = false;

        criteriaListContainers.forEach((criteria, index) => {
            if (index >= FilterPrinter.numCollapsedCriteria && containerCollapsed) {
                containerCollapsed.appendChild(criteria);
                if (criteria.querySelector('[data-action="filter"]').checked) {
                    checkedCriteriaInsideCollapse = true;
                }
            } else {
                criteriaContainer.appendChild(criteria);
            }
        });

        if (containerCollapsed) {
            criteriaContainer.appendChild(containerCollapsed);
            let collapseBtn = this.collapseBtnTemplate.cloneNode(true)
            collapseBtn.setAttribute('data-action', 'toggle-collapse');
            collapseBtn.setAttribute('data-target', '#crieria-type-' + indexCategory);
            collapseBtn.setAttribute('data-collapse-method', 'height');
            collapseBtn.setAttribute('data-collapse-height', '0');

            if (checkedCriteriaInsideCollapse) {
                collapseBtn.setAttribute('data-collapse-state', 'false');
            }

            collapseBtn.removeAttribute('data-template');
            collapseBtn.style.display = '';
            criteriaContainer.appendChild(collapseBtn);
            myApp.collapseManagerNew.registerCollapseButton(collapseBtn);
        }
    }

    _getCriteriaElement(criteria) {
        return document.querySelector('[data-action="filter"][data-filter-type="' + criteria.name + '"][data-valuea="' + criteria.valueA + '"]');
    }

    _updateCountTexts(filteredGroups, groups) {
        let text, element;
        let filterSummary = document.querySelector('[data-container="filter-title"][data-template-text]');
        let defaultFilterTitle = document.querySelector('[data-container="default-filter-title"]');

        if (filteredGroups.length === groups.length) {
            element = document.querySelector('[data-container="filter-button"][data-template-text-no-results]');
            text = element.dataset.templateTextNoResults;
            element.innerHTML = text;
            filterSummary.style.display = 'none';
            if (defaultFilterTitle) {
                defaultFilterTitle.style.display = '';
            }
            document.querySelector('[data-container="reset-filters-lg"]').style.display = 'none';
        } else {
            if (filteredGroups.length !== 0) {
                let text = filterSummary.dataset.templateText;
                filterSummary.style.display = '';
                text = text.replace('%currentCount%', filteredGroups.length);
                text = text.replace('%totalCount%', groups.length);
                filterSummary.innerHTML = text;

                element = document.querySelector('[data-container="filter-button"][data-template-text]');
                text = element.dataset.templateText;
                text = text.replace('%currentCount%', filteredGroups.length);
                element.innerHTML = text;
                if (defaultFilterTitle) {
                    defaultFilterTitle.style.display = 'none';
                }
            } else if (defaultFilterTitle && filterSummary){
                defaultFilterTitle.style.display = '';
                filterSummary.style.display = 'none';
            }
            document.querySelector('[data-container="reset-filters-lg"]').style.display = '';
        }
    }
}

FilterPrinter.numCollapsedCriteria = 8;