import LazyLoadManagerWebcomponents from '@web/web-components/js/components/lazyLoadManager.js';
export default class LazyLoadManager extends LazyLoadManagerWebcomponents {
    initialize(container) {

    }

    addLazyLoadAttributes(imageElement, imageUrl) {
        imageElement.setAttribute('loading', 'lazy');
        imageElement.setAttribute('src', imageUrl);
        return imageElement;
    }
}
